/*
 * @Description:计算动态高度
 * @Author: sunxiaodong
 * @Date: 2020-07-23 09:40:39
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2021-09-14 21:44:50
 */

/* import {
  addResizeListener,
  removeResizeListener
} from "e6-base-util/src/resize-event";
 */
import {
  addResizeListener,
  removeResizeListener
} from "e6yun-ui/lib/utils/resize-event";

const resizeListener = {
  data() {
    return {
      resizeViewHeight: 0
    };
  },
  methods: {
    getHeightSum(elsHeight, el) {
      return elsHeight + el.offsetHeight;
    },
    resizeListener: _.throttle(function() {
      let fixedSection = Array.from(
        document.querySelectorAll(".fixed-section")
      );
      let fixedSectionHeight = 0;
      const sectionWrapper = this.$refs.sectionWrapper;
      let sectionWrapperHeight = 0;
      fixedSectionHeight = fixedSection.reduce(this.getHeightSum, 0);
      sectionWrapperHeight = sectionWrapper.offsetHeight;
      this.resizeViewHeight = sectionWrapperHeight - fixedSectionHeight;
    }, 300)
  },
  mounted() {
    // 调用方法之前判断该节点是否存在
    if (this.$refs.sectionWrapper) {
      addResizeListener(this.$refs.sectionWrapper, this.resizeListener);
    }
  },
  beforeDestroy() {
    if (this.resizeListener) {
      removeResizeListener(this.$refs.sectionWrapper, this.resizeListener);
    }
  }
};

export default resizeListener;
