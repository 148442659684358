/*
 * @Description: 报表页面 resize
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2021-01-04 17:15:03
 * @LastEditTime: 2021-10-25 14:38:42
 */
export default {
  methods: {
    handleTableTitleResize() {
      this.$nextTick(() => {
        this.resizeListener();
      });
    }
  }
};
