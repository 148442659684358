<!--
 * @Description: 故障信息管理
 * @Author: ChenXueLin
 * @Date: 2021-10-15 15:24:28
 * @LastEditTime: 2022-07-15 16:44:59
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="firstClassId">
              <e6-vr-select
                v-model="searchForm.firstClassId"
                :data="firstClassList"
                placeholder="商品分类"
                title="商品分类"
                clearable
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isenable">
              <e6-vr-select
                v-model="searchForm.isenable"
                :data="statusList"
                placeholder="状态"
                title="状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstErrorReason">
              <el-input
                v-model="searchForm.firstErrorReason"
                placeholder="故障一级原因"
                title="故障一级原因"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondErrorReason">
              <el-input
                v-model="searchForm.secondErrorReason"
                placeholder="故障二级原因"
                title="故障二级原因"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleFault"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          :row-key="
            row => {
              return row.id;
            }
          "
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <el-dialog
        v-dialogDrag
        title="添加故障原因"
        :visible.sync="addFaultVisible"
        width="500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="addDialogLoading"
        :element-loading-background="loadingBackground"
        custom-class="custom-dialog"
      >
        <el-form
          ref="addFaultForm"
          label-width="120px"
          :model="addFaultForm"
          :rules="rules"
        >
          <el-form-item label="商品分类" prop="firstClassId">
            <e6-vr-select
              v-model="addFaultForm.firstClassId"
              :data="firstClassList"
              placeholder="商品分类"
              title="商品分类"
              clearable
              :props="{
                id: 'firstClassId',
                label: 'firstClassName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="故障类别" prop="errorClass">
            <e6-vr-select
              v-model="addFaultForm.errorClass"
              :data="errorClassList"
              placeholder="故障类别"
              title="故障类别"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="故障一级原因"
            prop="parentId"
            v-if="addFaultForm.errorClass == 2"
          >
            <e6-vr-select
              v-model="addFaultForm.parentId"
              :data="firstReasonList"
              placeholder="故障一级原因"
              title="故障一级原因"
              clearable
              v-loading="selectLoading"
              :props="{
                id: 'id',
                label: 'errorReason'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="故障原因" prop="errorReason">
            <el-input
              v-model.trim="addFaultForm.errorReason"
              placeholder="故障原因"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="addFaultForm.remark"
              placeholder="备注"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="toAdd">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getFirstClassList,
  getEquiperrorreason,
  disableError,
  findDownList,
  enableError,
  getReasonList,
  createError,
  updateError
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "faultManage",
  components: {},
  data() {
    return {
      total: 0,
      loading: false,
      addFaultVisible: false,
      firstClassList: [], //商品分类
      errorClassList: [], //故障类别
      firstReasonList: [], //一级故障原因
      statusList: [
        { id: 1, label: "启用" },
        { id: 0, label: "禁用" }
      ], //状态
      searchForm: {
        firstClassId: "",
        secondErrorReason: "",
        firstErrorReason: "",
        isenable: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "parentErrorReason",
          display: true,
          fieldLabel: "故障一级原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "errorReason",
          display: true,
          fieldLabel: "故障二级原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "errorClassName",
          display: true,
          fieldLabel: "问题等级",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isenableName",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      /******新增*********/
      addFaultForm: {
        errorClass: "",
        errorReason: "",
        firstClassId: "",
        parentId: "",
        remark: ""
      },
      addDialogLoading: false,
      rules: {},
      isAdd: true, //true为新增，false编辑
      selectLoading: false,
      refTable: "elTable",
      queryListAPI: getEquiperrorreason
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "addFaultForm.errorClass": {
      immediate: true,
      handler(val) {
        if (val == 1) {
          this.rules = {
            firstClassId: [
              {
                required: true,
                message: "请选择商品分类",
                trigger: ["blur", "change"]
              }
            ],
            errorClass: [
              {
                required: true,
                message: "请选择故障类别",
                trigger: ["blur", "change"]
              }
            ],
            errorReason: [
              {
                required: true,
                message: "请输入故障原因",
                trigger: ["blur", "change"]
              }
            ]
          };
        } else {
          this.rules = {
            firstClassId: [
              {
                required: true,
                message: "请选择商品分类",
                trigger: ["blur", "change"]
              }
            ],
            errorClass: [
              {
                required: true,
                message: "请选择故障类别",
                trigger: ["blur", "change"]
              }
            ],
            parentId: [
              {
                required: true,
                message: "请选择故障一级原因",
                trigger: ["blur", "change"]
              }
            ],
            errorReason: [
              {
                required: true,
                message: "请输入故障原因",
                trigger: ["blur", "change"]
              }
            ]
          };
        }
      }
    },
    "addFaultForm.firstClassId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getReasonListReq();
        } else {
          this.firstReasonList = [];
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [getFirstClassList(), findDownList(["errorClass"])];
        let [firstRes, errorRes] = await Promise.all(promiseList);
        //商品分类下拉框
        this.firstClassList = this.getFreezeResponse(firstRes, {
          path: "data"
        });
        //故障类别下拉框
        this.errorClassList = this.getFreezeResponse(errorRes, {
          path: "data.errorClass"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.id,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //启用、禁用
    changeStatus(type) {
      let tip, title;
      if (type == 1) {
        tip = "是否确认启用故障";
        title = "启用故障";
      } else {
        tip = "是否确认禁用故障";
        title = "禁用故障";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == 1) {
            this.enableErrorReq();
          } else {
            this.disableErrorReq();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //启用请求
    async enableErrorReq() {
      try {
        this.loading = true;
        let res = await enableError({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //禁用请求
    async disableErrorReq() {
      try {
        this.loading = true;
        let res = await disableError({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addFaultVisible = true;
        this.isAdd = false;
        this.addFaultForm.firstClassId = _.cloneDeep(row.firstClassId);
        this.addFaultForm.errorClass = _.cloneDeep(row.errorClass.toString());
        this.addFaultForm.errorReason = _.cloneDeep(row.errorReason);
        this.addFaultForm.parentId = _.cloneDeep(row.parentId);
        this.addFaultForm.remark = _.cloneDeep(row.remark);
        this.addFaultForm.id = _.cloneDeep(row.id);
        this.addFaultForm.versionNumber = _.cloneDeep(row.versionNumber);
      }
    },
    /*********新增***********/
    //获取故障一级原因查询
    async getReasonListReq() {
      try {
        this.selectLoading = true;
        let res = await getReasonList({
          firstClassId: this.addFaultForm.firstClassId,
          parentId: 0
        });
        this.firstReasonList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //点击确定
    toAdd() {
      this.$refs.addFaultForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.createErrorReq();
          } else {
            this.updateErrorReq();
          }
        }
      });
    },
    //新增故障原因
    async createErrorReq() {
      try {
        this.selectLoading = true;
        let res = await createError(this.addFaultForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //修改故障原因
    async updateErrorReq() {
      try {
        this.selectLoading = true;
        let res = await updateError(this.addFaultForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //添加故障条目
    handleFault() {
      this.addFaultVisible = true;
      this.isAdd = true;
    },
    //关闭弹框
    handleClose() {
      this.addFaultForm = {
        errorClass: "",
        errorReason: "",
        firstClassId: "",
        parentId: "",
        remark: ""
      };
      this.$refs.addFaultForm.resetFields();
      this.addFaultVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
