/*
 * @Description: 列表页面的混合
 * @Author: sunxiaodong
 * @Date: 2020-07-29 15:19:17
 * @LastEditors: sunxiaodong
 * @LastEditTime: 2020-08-06 14:21:29
 */
import setting from "../setting";
import resizeListener from "./resize-listener";
import base from "./base";

const { pageSize, pageSizes, layout } = setting;

export default {
  mixins: [base, resizeListener],

  data() {
    return {
      pageSize,
      pageSizes,
      layout
    };
  }
};
